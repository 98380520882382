<template>
  <div class="container">
    <h1>Vista de Práctica del Taller</h1>
    <h2>Lista de Estudiantes</h2>
    <!-- INICIO: Formulario de Estudiante -->
    <form v-on:submit.prevent="onClicGuardarEstudiante()">
      <!-- Input Nombre -->
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-6 col-xl-4 mb-2 d-flex flex-column align-items-start">
          <label for="inputNombre" class="form-label fw-bold">Nombre:</label>
          <input v-model="this.form.nombre" type="text" class="form-control"
                 id="inputNombre" placeholder="Escriba su nombre completo">
        </div>
      </div>
      <!-- Input Edad -->
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-6 col-xl-4 mb-2 d-flex flex-column align-items-start">
          <label for="inputEdad" class="form-label fw-bold">Edad:</label>
          <input v-model="this.form.edad" type="number" class="form-control"
                 id="inputEdad" placeholder="Escriba su edad">
        </div>
      </div>
      <!-- Input Carrera -->
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-6 col-xl-4 mb-2 d-flex flex-column align-items-start">
          <label for="inputCarrera" class="form-label fw-bold">Carrera:</label>
          <select v-model="this.form.carrera" id="inputCarrera"
                  class="form-select" aria-label="Selccione una carrera">
            <option value="" selected>Seleccione una carrera</option>
            <option value="Ingeniería en Sistemas">Ingeniería en Sistemas</option>
            <option value="Ingeniría Informática">Ingeniría Informática</option>
            <option value="Ingeniería en Redes">Ingeniería en Redes</option>
          </select>
        </div>
      </div>

      <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-6 col-xl-4 mb-2 d-flex flex-column align-items-end">
          <button type="submit" class="btn btn-primary w-auto">Guardar</button>
        </div>
      </div>

    </form>
    <!-- FIN: Formulario de Estudiante -->

    <!-- INICIO: Lista de Estudiantes -->
    <div v-for="(st, idx) in estudiantes" v-bind:key="idx"
         class="row d-flex justify-content-center">
      <div class="col-12 col-md-6 col-xl-4">
        <div class="card mt-2">
          <div class="card-body d-flex flex-column align-items-start">
            <p class="fs-5"><strong>Nombre:</strong> {{ st.nombre }}</p>
            <p class="fs-5"><strong>Edad:</strong> {{ st.edad }}</p>
            <p class="fs-5"><strong>Carrera:</strong> {{ st.carrera }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- FIN: Lista de Estudiantes -->

  </div>
</template>
<script>
export default {
  name: 'PracticaView',
  data() {
    return {
      form: {
        nombre: '',
        edad: 0,
        carrera: ''
      },
      estudiantes: [],
    }
  },
  methods: {
    onClicGuardarEstudiante() {
      this.estudiantes.push({
        nombre: this.form.nombre,
        edad: this.form.edad,
        carrera: this.form.carrera
      });
      localStorage.setItem('estudiantes', JSON.stringify(this.estudiantes));

      this.form.nombre = '';
      this.form.edad = 0;
      this.form.carrera = '';
    }
  },

  beforeCreate() {
    console.log("call beforeCreate");
  },
  created() {
    console.log("call created");
  },
  beforeMount() {
    if (localStorage.getItem('estudiantes')) {
      this.estudiantes = JSON.parse(localStorage.getItem('estudiantes'));
    }
    console.log("call beforeMount");
  },
  mounted() {
    console.log("call mounted");
  },
  beforeUpdate() {
    console.log("call beforeUpdate");
  },
  updated() {
    console.log("call updated");
  },
  beforeUnmount() {
    console.log("call beforeUnmount");
  },
  unmounted() {
    console.log("call unmounted");
  }
}
</script>